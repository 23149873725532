import React from 'react';
import { Index, Configure, connectStateResults } from 'react-instantsearch-dom';
import { Link } from 'react-router-dom';

import { stripDomain } from 'src/util/sanitizeLink';

import { NoResults } from './NoResults';
import classes from './SearchInner.scss';
import CustomHighlight from './Highlight';

export const CustomListHits = connectStateResults(({ searchResults, searching }) => {
    const hits = searchResults?.hits;
    const hasResults = !searching && hits && hits?.length > 0;

    return (
        <div className={classes.root}>
            <h3 className={classes.title}>Categories</h3>

            {!hasResults && <NoResults />}

            {hasResults && (
                <ul className={classes.listHits}>
                    {hits.map((hit) => (
                        <li key={hit.objectID} className={classes.listItem}>
                            <Link to={stripDomain(hit.url)} className={classes.listItemLink}>
                                <CustomHighlight attribute="path" hit={hit} />
                            </Link>
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );
});

type AlgoliaCategoriesProps = {
    index: string;
};

export function AlgoliaCategories(props: AlgoliaCategoriesProps) {
    return (
        <Index indexName={props.index}>
            <Configure hitsPerPage={3} />
            <CustomListHits />
        </Index>
    );
}
