import { AppendCartState } from '@wearejh/m2-pwa-cart-gql/lib/cart.reducer';

export const cartSelector = (state: AppendCartState) => {
    return {
        cartId: state.cart.cartId,
        items: state.cart.items,
    };
};

export default cartSelector;
