import type { ProductPrice } from 'src/types/global-types';

import type { NostoProductDataRaw, NostoProductData } from '../types';

const truncate = (content: string, maxLength: number) => {
    if (content.length > maxLength) {
        return content.substring(0, maxLength - 3) + '...';
    }

    return content;
};

const findDataInTags = (string: string, tags: string[]) => {
    let result = '';

    tags.forEach((tag) => {
        if (tag.includes(string)) {
            result = tag.split(':')[1];
        }
    });

    return result;
};

export const formatProducts = (products: NostoProductDataRaw[] | undefined): NostoProductData[] => {
    if (!Array.isArray(products)) return [];

    const cleanedNostoProducts: NostoProductData[] = [];

    products.map((product) => {
        const urlWithoutDomain = product.url.split('woodies.ie')?.[1];
        const regularPrice = product.list_price;
        const currentPrice = product.price;
        const clickAndCollect = findDataInTags('click_and_collected', product.tags1);
        const homeDelivery = findDataInTags('delivery_type', product.tags1);
        const isHomeDelivery = homeDelivery ? homeDelivery !== '-' : false;

        const price: ProductPrice = {
            price: currentPrice,
            price_incl: currentPrice,
        };

        if (currentPrice !== regularPrice) {
            price.regular_price = regularPrice;
            price.discount = regularPrice - currentPrice;
        }

        const formattedProduct = {
            id: product.product_id ?? '',
            name: product.name ?? '',
            description: product.description ? truncate(product.description, 50) : '',
            url: urlWithoutDomain ?? product.url ?? '/',
            price,
            sku: product.custom_fields?.sku ?? '',
            small_image: {
                label: product.name ?? '',
                url: product.image_url ?? '',
            },
            click_collect: clickAndCollect === 'Yes',
            delivery: isHomeDelivery,
            labels: [],
            rating_value: product.rating_value,
            review_count: product.review_count,
        };

        cleanedNostoProducts.push(formattedProduct);
    });

    return cleanedNostoProducts;
};

export default formatProducts;
