import { shallowEqual, useSelector } from 'react-redux';
import { AppendState } from '@wearejh/m2-pwa-user/lib/user.reducer';
import { CustomerDataCustomerInterface } from 'swagger/ts/Definitions';

interface UseGetCustomerGroupIDApi {
    groupId: CustomerDataCustomerInterface['group_id'];
}

const customerSelector = (state: AppendState) => state.user.data?.group_id || 0;

/**
 * Please be aware when using this hook. The group_id property of the customer is currently deprecated. However, we need
 * to use this for fetching certain label data from Algolia, as there is no way to distinguish customer group on their end.
 *
 * This isn't need for anything related to Magento, as Magento automatically handles this based on the token sent with
 * requests.
 *
 * It should default to 0 = guest.
 */
export const useGetCustomerGroupID = (): UseGetCustomerGroupIDApi => {
    const groupId = useSelector(customerSelector, shallowEqual);

    return {
        groupId,
    };
};
