import React from 'react';
import { connectHighlight } from 'react-instantsearch-dom';

export const Highlight = ({ highlight, attribute, hit }) => {
    const parsedHit = highlight({
        highlightProperty: '_highlightResult',
        attribute,
        hit,
    });
    return (
        <span>
            {parsedHit.map((part, index) =>
                part.isHighlighted ? <b key={index}>{part.value}</b> : <span key={index}>{part.value}</span>,
            )}
        </span>
    );
};

export const CustomHighlight = connectHighlight(Highlight);

export default CustomHighlight;
