import type { ReactNode } from 'react';
import classnames from 'classnames';
import React, { forwardRef } from 'react';

import styles from 'src/components/Modal/Modal.scss';

interface AddToBasketModalOverlayProps {
    children: ReactNode;
}

export const AddToBasketModalOverlay = forwardRef<HTMLDivElement, AddToBasketModalOverlayProps>((props, ref) => {
    return (
        <div className={styles['overlay']}>
            <div
                ref={ref}
                role="alertdialog"
                aria-modal="true"
                aria-labelledby="atb_modal_label"
                aria-describedby="atb_modal_desc"
                className={classnames({
                    [styles['root']]: true,
                    [styles['popup']]: true,
                    [styles['wide']]: true,
                })}
            >
                {props.children}
            </div>
        </div>
    );
});

export default AddToBasketModalOverlay;
