import { DEFAULT_CURRENCY } from 'src/components/AppShell/app-shell.types';

import { NostoCartProducts } from '../types';

export const normalizeNostoCartProducts = (items): NostoCartProducts[] | undefined => {
    return items?.map((item) => {
        const productInfo = item.cartItem?.product || item.product;
        const itemInfo = item.cartItem || item;

        return {
            name: productInfo.name ?? '',
            price_currency_code: DEFAULT_CURRENCY,
            product_id: productInfo.id.toString() ?? '',
            quantity: itemInfo.quantity,
            sku_id: productInfo.sku.toString(),
            unit_price: itemInfo.prices.price,
        };
    });
};

export default normalizeNostoCartProducts;
