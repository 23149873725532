import React, { useEffect } from 'react';
import { DialogOverlay, DialogContent } from '@reach/dialog';
import classnames from 'classnames';
import { disablePageScroll, enablePageScroll } from 'scroll-lock';

import classes from './Modal.scss';

type ModalType = 'sidebar' | 'popup' | 'popup-wide' | 'sidebar-small';

type ModalProps = {
    /**
     * Label to describe modal to assistive technologies.
     */
    ariaLabel?: string;
    children: React.ReactNode;
    className?: string;
    contentClassName?: string;
    isOpen?: boolean;
    onDismiss: () => void;
    type?: ModalType;
};

export function Modal(props: ModalProps) {
    return (
        <DialogOverlay
            allowPinchZoom={true}
            className={classnames(classes.overlay, props.className)}
            data-test-id="Modal"
            isOpen={props.isOpen}
            onDismiss={props.onDismiss}
            dangerouslyBypassScrollLock
        >
            <DialogContent
                aria-label={props.ariaLabel}
                className={classnames(
                    {
                        [classes.root]: true,
                        [classes.popup]: props.type === 'popup' || props.type === 'popup-wide',
                        [classes.popupWide]: props.type === 'popup-wide',
                        [classes.sidebar]: props.type === 'sidebar' || props.type === 'sidebar-small',
                        [classes.sidebarSmall]: props.type === 'sidebar-small',
                    },
                    props.contentClassName,
                )}
            >
                <div className={classes.modalContent}>{props.children}</div>
                <ScrollLock />
            </DialogContent>
        </DialogOverlay>
    );
}

Modal.defaultProps = {
    ariaLabel: 'Modal Content',
    isOpen: false,
    type: 'popup',
};

const ScrollLock: React.FC = () => {
    useEffect(() => {
        const $scrollableElement = document.querySelector(`[data-reach-dialog-overlay]`);
        if ($scrollableElement) {
            disablePageScroll($scrollableElement);
        }
        return () => {
            if ($scrollableElement) {
                enablePageScroll($scrollableElement);
            }
        };
    }, []);
    return null;
};
