/**
 * UserToken must contain only:
 * alphanumeric,
 * equal,
 * plus,
 * slash,
 * hyphen,
 * underscores,
 * and be between 1 and 129 characters long
 * @param token
 */
export function sanitizeGuestUserToken(token: string): string {
    const regex = /[^a-zA-Z0-9=+\/\-_]/g;
    const sanitizedToken = token.replace(regex, '_');
    return sanitizedToken.length > 129 ? sanitizedToken.substring(0, 129) : sanitizedToken;
}
