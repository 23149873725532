import React, { useEffect, useMemo } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { CartItem } from '@wearejh/m2-pwa-cart-gql';

import { HeaderVariant } from 'src/components/Header/HeaderVariant';
import { BrandSchema } from 'src/components/AppShell/BrandStructuredData';
import { ConnectedModalPortal } from 'src/components/ModalPortal/ConnectedModalPortal';
import { FooterWrap } from 'src/components/Footer/Footer';
import { useGetAddToBasketModalState } from 'src/components/AtbModal/hooks';
import { cartSelector, routerSelector } from 'src/components/AtbModal/selectors';

import { Main } from '../Main/Main';
import { Scripts } from '../Scripts/Scripts';

import { Head } from './Head';
import { ConfigProvider } from './ConfigProvider';
import { OfflineRibon } from './OfflineRibon';
import { stateSelector } from './selectors';

function OfflineOutdated() {
    const state = useSelector(stateSelector, shallowEqual);

    useEffect(() => {
        if (state.outdated) {
            window.location.reload();
        }
    }, [state.outdated]);

    return !state.online ? <OfflineRibon /> : null;
}

export const AppShell: React.FC = React.memo((props) => {
    const { isRouteResolving } = useSelector(routerSelector, shallowEqual);
    const { items } = useSelector(cartSelector);

    const dispatch = useDispatch();

    const NostoItemTagging = (props: { items: CartItem[] }) => {
        // Use this to tag what is in the cart
        // Required for Nosto to know what to do with recommendations
        // Have this appear on every page so that Nosto can do recommendations from anywhere on the site
        useEffect(() => {
            (window as any).nostojs((api) => api.loadRecommendations());
        }, [props.items]);

        return useMemo(() => {
            return (
                <div className="nosto_cart" style={{ display: 'none' }}>
                    {props.items.map((item) => {
                        return (
                            <div className="line_item" key={item.product.sku}>
                                <span className="product_id">{item.product.id}</span>
                                <span className="quantity">{item.quantity}</span>
                                <span className="name">{item.product.name}</span>
                                <span className="unit_price">{item.prices.price}</span>
                                <span className="price_currency_code">EUR</span>
                            </div>
                        );
                    })}
                </div>
            );
        }, [props.items]);
    };

    const { isAddToBasketModalOpen } = useGetAddToBasketModalState();

    useEffect(() => {
        /**
         * 1. If an Add to basket Modal is open
         * 2. And the router is resolving to the next page
         * 3. Then we need to close the modal before completion
         * 4. So we need to dispatch the AddToBasketModal.Close event
         *
         * Doing this, ensures that the modal stays closed between page switching
         */
        if (isAddToBasketModalOpen && isRouteResolving) {
            dispatch({ type: 'AddToBasketModal.Close' });
        }
    }, [dispatch, isAddToBasketModalOpen, isRouteResolving]);

    return (
        <ConfigProvider>
            <BrandSchema />
            <Head />
            <Scripts />
            <HeaderVariant />
            <Main>
                <NostoItemTagging items={items} />
                <OfflineOutdated />
                {props.children}
            </Main>
            <div style={{ minHeight: '130px' }}>
                <FooterWrap />
            </div>
            <ConnectedModalPortal />
        </ConfigProvider>
    );
});
