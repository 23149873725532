import React, { useContext, useEffect } from 'react';
import { InstantSearch, connectSearchBox, Configure } from 'react-instantsearch-dom';
import { debounceTime, skip, tap } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';

import { CustomSearchForm } from 'src/components/Algolia/SearchDropdown/SearchForm';
import { useBreakpointMatch } from 'src/hooks/useBreakpoint';

import { AlgoliaContext } from './AlgoliaContext';
import { SearchAutocompleteDropdown } from './SearchAutocompleteDropdown';

export type SearchTrigger = BehaviorSubject<string>;

type SearchAutocompleteWrapperProps = {
    searchTerm: SearchTrigger;
    setShowSearch: (val: boolean) => void;
    showSearch: boolean;
};

export function SearchAutocompleteWrapper(props: SearchAutocompleteWrapperProps) {
    const { client, indexes } = useContext(AlgoliaContext);
    const { isMob } = useBreakpointMatch();
    return (
        <>
            <CustomSearchForm searchTerm={props.searchTerm} setShowSearch={props.setShowSearch} />
            {props.showSearch && (
                <InstantSearch searchClient={client} indexName={indexes.products}>
                    <Configure clickAnalytics hitsPerPage={isMob ? 3 : 8} />
                    <Searcher searchTerm={props.searchTerm} defaultRefinement={props.searchTerm.value} />
                    <SearchAutocompleteDropdown />
                </InstantSearch>
            )}
        </>
    );
}

const Searcher = connectSearchBox((props) => {
    const { refine, searchTerm } = props;

    useEffect(() => {
        const sub = searchTerm
            .pipe(
                skip(1),
                debounceTime(500),
                tap((x) => {
                    refine(x);
                }),
            )
            .subscribe();

        return () => sub.unsubscribe();
    }, [refine, searchTerm]);

    return null as any;
});
