export function convertOptionName(name: string): string {
    switch (name) {
        case 'price.GBP.default':
            return 'Price';
        case 'price.EUR.default':
            return 'Price';
        case 'colour':
            return 'Colour';
        // case 'categories_without_path':
        //     return 'Category';
        // case 'depth_metric':
        //     return 'Depth';
        default: {
            const splitString = name.split('_');
            const upperSplitString = splitString.map((string) => {
                return string.charAt(0).toUpperCase() + string.substring(1);
            });
            return upperSplitString.join(' ');
        }
    }
}
