import React, { ReactNode, useEffect, useMemo, useRef, useState } from 'react';

import { NostoPlacementsProps, NostoRecommendations } from 'src/components/Nosto/types';
import { configureNosto } from 'src/components/Nosto/utilities/configureNosto';

import type { NostoPageTypeProps } from './NostoPageType';
import NostoPageType from './NostoPageType';

export type NostoContextType = {
    isLoaded: boolean;
    nostoRecommendations: Record<string, NostoRecommendations> | undefined;
};

export const defaultNostoContext = {
    isLoaded: false,
    nostoRecommendations: undefined,
};

export const NostoContext = React.createContext<NostoContextType>(defaultNostoContext);

export type NostoProviderProps = Pick<NostoPageTypeProps, 'pageType'> & {
    children: ReactNode;
    nostoData?: NostoPlacementsProps;
};

export const NostoContextProvider = (props: NostoProviderProps) => {
    const [nostoRecommendations, setNostoRecommendations] = useState<Record<string, NostoRecommendations> | undefined>(
        undefined,
    );

    const isLoaded = useRef<boolean>(false);

    /**
     * Make sure the nosto script has loaded and the api is available
     */
    useEffect(() => {
        // if the nosto script is already loaded, do nothing
        if (isLoaded.current) return;

        // if the nosto script is not loaded, load it
        const hasNostoWindowObject = Boolean((window as any).nostojs);

        // If Nosto is not loaded or the data is not available, do nothing
        if (!hasNostoWindowObject && !props.nostoData) return;

        isLoaded.current = true;
    }, [props.nostoData, props.nostoData?.type]);

    /**
     * If the Nosto script is loaded, fetch the recommendations
     * Fetch Nosto recommendations
     */
    useEffect(() => {
        if (isLoaded.current) {
            (window as any).nostojs((api) => {
                // Fetch Nosto recommendations
                if (props.nostoData?.type) {
                    const fetchNostoRecommendations = async () => {
                        try {
                            if (props.nostoData?.type) {
                                // Fetch recommendations for each type
                                for (const type of props.nostoData.type) {
                                    // Configure Nosto for the current type
                                    const response = await configureNosto({
                                        api,
                                        placementType: type,
                                        ...props.nostoData,
                                    });

                                    // Update the state with the recommendations
                                    setNostoRecommendations((prev) => ({
                                        ...prev,
                                        [type]: response,
                                    }));
                                }
                            }
                        } catch (error) {
                            console.error('Failed to configure Nosto:', error);
                        }
                    };
                    fetchNostoRecommendations();
                }
            });
        }
    }, [props.nostoData]);

    const api = useMemo(() => {
        return {
            isLoaded: isLoaded.current,
            nostoRecommendations,
        };
    }, [nostoRecommendations]);

    return (
        <NostoContext.Provider value={api}>
            <NostoPageType pageType={props.pageType} />
            {props.children}
        </NostoContext.Provider>
    );
};

export default NostoContextProvider;
