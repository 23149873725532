import React, { useCallback, useContext, useEffect, useRef } from 'react';
import { BehaviorSubject, merge } from 'rxjs';
import { debounceTime, distinctUntilChanged, skip } from 'rxjs/operators';
import { useConstant } from '@wearejh/react-hooks/lib/useConstant';
import classnames from 'classnames';

import classes from 'src/components/Algolia/SearchDropdown/SearchLayout.scss';
import { SearchAutocompleteWrapper } from 'src/components/Algolia/SearchDropdown/SearchAutocompleteWrapper';
import { AlgoliaContextProvider } from 'src/components/Algolia/SearchDropdown/AlgoliaContext';
import { HeaderContext } from 'src/components/Header/Header';
import { useClickOutside } from 'src/hooks/useClickOutside';
import { Button } from 'src/components/Button/Button';
import { Svg } from 'src/components/Svg/Svg';

export function SearchAutocomplete() {
    const { showSearch, setShowSearch } = useContext(HeaderContext);

    const outerRef = useRef<HTMLDivElement | null>(null);

    const close = useCallback(() => setShowSearch(false), [setShowSearch]);

    useClickOutside(outerRef, showSearch, close);

    const searchTermSubscribe = useConstant(() => new BehaviorSubject<string>(''));

    useEffect(() => {
        const sub = merge(searchTermSubscribe).pipe(skip(1), debounceTime(100), distinctUntilChanged()).subscribe();
        return () => {
            sub.unsubscribe();
        };
    }, [searchTermSubscribe]);

    return (
        <div ref={outerRef} className={classes.search}>
            <Button
                className={classnames({
                    [classes.searchClose]: true,
                    [classes.searchCloseActive]: showSearch,
                })}
                onClick={() => setShowSearch(false)}
            >
                <Svg name="close" />
            </Button>

            <AlgoliaContextProvider>
                <SearchAutocompleteWrapper
                    searchTerm={searchTermSubscribe}
                    setShowSearch={setShowSearch}
                    showSearch={showSearch}
                />
            </AlgoliaContextProvider>
        </div>
    );
}
