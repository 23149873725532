import React from 'react';
import { Index, connectStateResults, Hits } from 'react-instantsearch-dom';

import { ButtonLink } from 'src/components/Button/ButtonLink';

import { NoResults } from './NoResults';
import classes from './AlgoliaProducts.scss';
import { AlgoliaStats } from './AlgoliaStats';
import { AlgoliaProductHit } from './AlgoliaProductHit';

export const AlgoliaProducts = connectStateResults(({ searchResults, searching, index }) => {
    const hasResults = searchResults && searchResults.nbHits !== 0;
    const queryString = (searchResults && searchResults.query) || '';

    return (
        <Index indexName={index}>
            <div className={classes.products}>
                <div className={classes.productsHeader}>
                    <h3 className={[classes.productsTitle].join(' ')}>
                        Products
                        {hasResults && (
                            <>
                                <span className={classes.productsTitleStats}>
                                    <AlgoliaStats />
                                </span>
                                <ButtonLink
                                    variant="reset"
                                    to={`/catalogsearch/results?query=${queryString}`}
                                    className={classes.productsTitleAction}
                                >
                                    View all
                                </ButtonLink>
                            </>
                        )}
                    </h3>
                </div>

                {!hasResults && !searching && (
                    <div className={classes.productHits}>
                        <NoResults />
                    </div>
                )}

                {hasResults && (
                    <>
                        <div className={classes.productHits}>
                            <Hits hitComponent={AlgoliaProductHit} />
                        </div>

                        <div className={classes.productHitsActions}>
                            <ButtonLink
                                to={`/catalogsearch/results?query=${queryString}`}
                                size="small"
                                className={classes.productHitsButton}
                            >
                                View all products
                            </ButtonLink>
                        </div>
                    </>
                )}
            </div>
        </Index>
    );
});
