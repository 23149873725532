import React, { useContext } from 'react';
import classnames from 'classnames';
import { Link } from 'react-router-dom';

import { useDeps } from 'src/hooks/useDeps';

import { Svg } from '../Svg/Svg';

import classes from './HeaderControls.scss';
import { ConnectedBasketIcon } from './BasketIcon';
import { AccountIcon } from './AccountIcon';
import { HeaderContext } from './Header';

export const HeaderControls = React.memo(function HeaderControls() {
    const { showNav, setShowNav } = useContext(HeaderContext);
    const { paths } = useDeps();

    return (
        // New icon for Community. Hidden below 400px.
        <div className={classes.headerControls}>
            <Link
                to={paths.community.joinCommunity}
                className={classnames({
                    [classes.headerControl]: true,
                    [classes.headerControlCommunity]: true,
                })}
            >
                <Svg name="community-icon" />
                <span className={classes.headerControlContent}>
                    Join our
                    <br /> Community
                </span>
            </Link>
            <Link
                data-test-id="HeaderControls__TipsAndAdvice"
                to="/click-collect"
                className={classnames({
                    [classes.headerControl]: true,
                    [classes.headerControlTips]: true,
                })}
            >
                <Svg name="click-collect" />
                <span className={classes.headerControlContent}>
                    Click &<br /> Collect
                </span>
            </Link>
            <Link
                to={paths.storeLocator.storeLocator}
                className={classnames({
                    [classes.headerControl]: true,
                    [classes.headerControlContact]: true,
                })}
            >
                <Svg name="place" />
                <span className={classes.headerControlContent}>Stores</span>
            </Link>
            <AccountIcon />
            <ConnectedBasketIcon />
            <button
                data-test-id="Menu_btn"
                className={classnames({
                    [classes.headerControl]: true,
                    [classes.headerControlMenu]: true,
                    [classes.headerControlMenuActive]: showNav,
                })}
                onClick={() => {
                    setShowNav(!showNav);
                }}
                type="button"
            >
                {!showNav && <Svg name="menu" />}
                {showNav && <Svg name="close" />}
                <span className={classes.headerControlContent}>{showNav ? 'Close' : 'Menu'}</span>
            </button>
        </div>
    );
});
