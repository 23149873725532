import React, { useContext } from 'react';

import { ConfigContext } from 'src/components/AppShell/ConfigProvider';
import { useDeps } from 'src/hooks/useDeps';

export function Scripts() {
    const context = useContext(ConfigContext);
    const isGtmEnabled = context.gtm.enabled;
    const gtmId = context.gtm.id;
    const { NOSTO_ID } = useDeps().env;

    return (
        <>
            <script
                src={`https://connect.nosto.com/include/${NOSTO_ID}`}
                async
                type="text/javascript"
                nosto-client-script
            />

            {isGtmEnabled && (
                <>
                    <script
                        async
                        id="gtm-script"
                        dangerouslySetInnerHTML={{
                            __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','${gtmId}');`,
                        }}
                    ></script>

                    <noscript
                        dangerouslySetInnerHTML={{
                            __html: `<iframe src="https://www.googletagmanager.com/ns.html?id=${gtmId}"height="0" width="0" style="display:none;visibility:hidden"></iframe>`,
                        }}
                    ></noscript>
                </>
            )}
        </>
    );
}
