import classnames from 'classnames';
import React from 'react';

import { ProductHitLabelData } from 'src/components/Algolia/utils/algolia.types';

import classes from './ProductLabelsList.scss';
import { SingleLabel, ProductLabel } from './ProductLabel';

export type ProductLabelsListProps = {
    className?: string;
    direction?: 'column' | 'row';
    labels?: (SingleLabel | ProductHitLabelData)[];
    size?: 'micro' | 'default';
};

/**
 * Group of product labels
 */
export function ProductLabelsList(props: ProductLabelsListProps) {
    if (!props.labels || props.labels.length === 0) {
        return null;
    }

    return (
        <div className={classnames(classes.productLabelsList__container, props.className)}>
            <ul className={classes.productLabelsList} data-direction={props.direction}>
                {props.labels.map((label) => {
                    if (label.id === null || label.name === null || label.type === null) return;

                    return (
                        <li className={classes.productLabelsList__item} key={label.id}>
                            <ProductLabel {...label} size={props.size} />
                        </li>
                    );
                })}
            </ul>
        </div>
    );
}

ProductLabelsList.defaultProps = {
    className: null,
    direction: 'row',
};
