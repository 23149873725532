import { Observable } from 'rxjs';
import { CurrencyState } from '@wearejh/m2-pwa-currency/lib/browser';
import { first, ignoreElements, pluck, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { CartActions, CartState } from '@wearejh/m2-pwa-cart-gql/lib/index';
import { ofType } from 'redux-observable';

import { DEFAULT_CURRENCY } from 'src/components/AppShell/app-shell.types';
import { fireGTMEvent } from 'src/features/analytics/hooks/useGtm';

export function gtmAddToCart(action$: Observable<any>, state$: Observable<any>): Observable<never> {
    const $currentCurrency: Observable<CurrencyState['code']> = state$.pipe(pluck('currency', 'code'));
    const $currentProduct: Observable<CartState['items']> = state$.pipe(pluck('cart', 'items'));
    return action$.pipe(
        ofType<CartActions>('Cart.AddSuccess'),
        switchMap(() => action$.pipe(ofType<CartActions>('Cart.FetchSuccess'), first())),
        withLatestFrom($currentProduct, $currentCurrency),
        tap(([, items, currentCurrency]) => {
            const item = items[items.length - 1];
            if (!item) return;
            fireGTMEvent({
                event: 'add_to_cart',
                ecommerce: {
                    currency: currentCurrency || DEFAULT_CURRENCY,
                    value: item.prices.price,
                    items: [
                        {
                            item_id: item.product.sku,
                            item_name: item.product.name,
                            quantity: item.quantity.toString(),
                            value: item.prices.price,
                        },
                    ],
                },
            });
        }),
        ignoreElements(),
    );
}
