import React, { useCallback } from 'react';

import { useSmoothScrollToSelector } from 'src/hooks/useSmoothScrollToSelector';

import { ReviewStars } from './ReviewStars';
import classes from './ReviewCount.scss';

const REVIEWS_SELECTOR = '#ProductReviews';

type Props = {
    starCount: number;
    reviewCount: number;
    canWrite: boolean;
    showTotal: boolean;
};

/**
 * @param props
 * @constructor
 */
export function ReviewCount(props: Props) {
    const scrollTo = useSmoothScrollToSelector();
    const onClick = useCallback(
        (e) => {
            e.preventDefault();
            scrollTo(REVIEWS_SELECTOR);
        },
        [scrollTo],
    );
    return (
        <div className={classes.root}>
            <ReviewStars id="review" count={props.starCount.toString()} onClick={onClick} />
            {props.reviewCount === 0 && props.canWrite && (
                <a href={REVIEWS_SELECTOR} onClick={onClick} className={classes.link}>
                    Write a review
                </a>
            )}
            {props.reviewCount > 0 && props.showTotal && (
                <a href={REVIEWS_SELECTOR} onClick={onClick} className={classes.link}>
                    {props.reviewCount} review{props.reviewCount === 1 ? '' : 's'}
                </a>
            )}
        </div>
    );
}
