import React from 'react';

import classes from './NoResults.scss';

export function NoResults() {
    return (
        <div>
            <p className={classes.text}>No matches</p>
        </div>
    );
}
