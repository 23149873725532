import type { AppendState } from '@wearejh/m2-pwa-cart-gql/lib/cart.reducer';
import type { State } from '@wearejh/m2-pwa-checkout/lib/checkout.reducer';

import type { State as AtbState } from '../types';
import { Value } from '../types';

type AppendAtbState<T = State> = T & {
    addToBasketModal: AtbState;
};

export const selector = (state: AppendState<AppendAtbState>) => {
    const items = state.addToBasketModal?.items;
    const isOpen = state.addToBasketModal?.value === Value.Open;

    return {
        isOpen,
        items,
    };
};

export default selector;
