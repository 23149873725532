import { shallowEqual, useSelector } from 'react-redux';

import { selector } from './selector';

interface UseGetAddToBasketModalState {
    isAddToBasketModalOpen?: boolean;
}

export const useGetAddToBasketModalState = (): UseGetAddToBasketModalState => {
    const { isOpen } = useSelector(selector, shallowEqual);

    return {
        isAddToBasketModalOpen: isOpen ?? undefined,
    };
};

export default useGetAddToBasketModalState;
