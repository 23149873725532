import React from 'react';
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';
import { useSetResolved } from '@wearejh/m2-pwa-engine/lib/router';

import { NostoContextProvider } from 'src/components/Nosto/NostoProvider';
import { Nosto } from 'src/components/Nosto/Nosto';

import { Container, Wrapper } from '../Layout';
import { PageTitle } from '../Layout/PageTitle';
import { ProductAddToBasketModal } from '../AtbModal/ProductAddToBasketModal';

type NotFoundProps = Record<string, unknown>;

export const NotFound: React.FC<NotFoundProps> = () => {
    useSetResolved();
    return (
        <>
            <Helmet>
                <title>404 Not Found</title>
            </Helmet>
            <NostoContextProvider
                nostoData={{
                    type: ['addToBasket', 'notfound'],
                }}
                pageType="notfound"
            >
                <Wrapper variant="page-inner">
                    <Container>
                        <PageTitle title="Page Not Found" />
                        <p className="lede">We are sorry, but the page you are looking for cannot be found.</p>
                        <ul>
                            <li>If you typed the URL directly, please make sure the spelling is correct.</li>
                            <li>
                                If you clicked on a link to get here, we must have moved the content.
                                <br />
                                Please try our store search box above to search for an item.
                            </li>
                            <li>
                                If you are not sure how you got here,{' '}
                                <a href="#" onClick={() => history.go(-1)}>
                                    go back
                                </a>{' '}
                                to the previous page or return to our <Link to="/">store homepage</Link>.
                            </li>
                        </ul>
                        <Nosto type="notfound" />
                    </Container>
                </Wrapper>
                <ProductAddToBasketModal />
            </NostoContextProvider>
        </>
    );
};
