import { CartItem } from '@wearejh/m2-pwa-cart-gql';

import { gtmAddToCart } from 'src/features/analytics/epics/gtmAddToCart';
import { Events, State, Value } from 'src/components/AtbModal/types';

import { addSuccess } from './epics/addSuccess.epic';

type AddToBasketModalState = { value: Value; items?: CartItem[] };

const defaultState = {
    value: Value.Closed,
};

export const addToBasketModalRegister = () => {
    return {
        epics: [addSuccess, gtmAddToCart],
        name: 'addToBasketModal',
        reducers: {
            addToBasketModal: (state: AddToBasketModalState = defaultState, action: Events): State => {
                switch (action.type) {
                    case 'AddToBasketModal.Close':
                        return {
                            ...state,
                            value: Value.Closed,
                            items: undefined,
                        };

                    case 'AddToBasketModal.Open':
                        return {
                            ...state,
                            value: Value.Open,
                            items: action.items,
                        };

                    default:
                        return state;
                }
            },
        },
    };
};

export default addToBasketModalRegister;
