import classNames from 'classnames';
import React, { PropsWithChildren } from 'react';

import classes from './Modal.scss';

interface ModalBodyProps {
    /** Append an additional classname */
    className?: string;
}

export function ModalBody(props: PropsWithChildren<ModalBodyProps>) {
    return <div className={classNames(classes.body, props.className)}>{props.children}</div>;
}
