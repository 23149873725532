import { Message } from '@wearejh/m2-pwa-engine/lib/types';

import { ModalPortalType } from 'src/components/ModalPortal/ModalPortal.types';

import { Actions } from './app.actions';

export interface ModalState {
    isActive: boolean;
    type: ModalPortalType;
}

export type AppState = {
    openNav: boolean;
    openSearch: boolean;
    openOverlay: boolean;
    canOpen: boolean;
    openSubNavs: number[];
    renderEnv: 'server' | 'browser';
    messages: Message[];
    modalState: ModalState;
};

export const initialState: AppState = {
    openNav: false,
    openSearch: false,
    openOverlay: false,
    canOpen: true,
    openSubNavs: [],
    renderEnv: 'server',
    messages: [],
    modalState: {
        isActive: false,
        type: ModalPortalType.Default,
    },
};

export function appReducer(state = initialState, action: Actions): AppState {
    switch (action.type) {
        case 'App.OpenNav': {
            return {
                ...state,
                openNav: action.payload,
                openOverlay: action.payload,
                openSearch: false,
            };
        }
        case 'App.OpenSubNav': {
            if (!state.canOpen) {
                return state;
            }
            return {
                ...state,
                openSubNavs: action.payload,
            };
        }
        case 'App.EnableNav': {
            return {
                ...state,
                canOpen: true,
            };
        }
        case 'App.CloseSubNav': {
            return {
                ...state,
                openSubNavs: state.openSubNavs.filter((x) => x !== action.payload),
            };
        }
        case 'App.CloseAllNavs': {
            return {
                ...state,
                openSubNavs: [],
                openNav: false,
                canOpen: false,
                openOverlay: false,
            };
        }
        case 'App.ToggleSearch': {
            if (state.openNav) {
                return {
                    ...state,
                    openSearch: action.payload,
                    openNav: false,
                    openSubNavs: [],
                };
            } else {
                return {
                    ...state,
                    openSearch: action.payload,
                    openOverlay: action.payload,
                };
            }
        }
        case 'App.AddMessages': {
            return {
                ...state,
                messages: action.payload,
            };
        }
        case 'App.ClearMessages': {
            return {
                ...state,
                messages: [],
            };
        }

        case 'App.Modal.Close': {
            return {
                ...state,
                modalState: {
                    ...state.modalState,
                    isActive: false,
                    type: ModalPortalType.Default,
                },
            };
        }

        case 'App.Modal.Open.ForgotPassword': {
            return {
                ...state,
                modalState: {
                    ...state.modalState,
                    isActive: true,
                    type: ModalPortalType.ForgotPassword,
                },
            };
        }

        case 'App.Modal.Open.Register': {
            return {
                ...state,
                modalState: {
                    ...state.modalState,
                    isActive: true,
                    type: ModalPortalType.Register,
                },
            };
        }

        case 'App.Modal.Open.SignIn': {
            return {
                ...state,
                modalState: {
                    ...state.modalState,
                    isActive: true,
                    type: ModalPortalType.SignIn,
                },
            };
        }

        case 'App.Modal.Open': {
            return {
                ...state,
                modalState: {
                    ...state.modalState,
                    isActive: true,
                },
            };
        }

        case 'App.Modal.OpenType': {
            return {
                ...state,
                modalState: {
                    ...state.modalState,
                    isActive: true,
                    type: action.payload,
                },
            };
        }

        case 'App.Modal.SetType': {
            return {
                ...state,
                modalState: {
                    ...state.modalState,
                    type: action.payload,
                },
            };
        }

        default:
            return state;
    }
}
