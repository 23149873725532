import { Observable } from 'rxjs';
import { ignoreElements, pluck, tap, withLatestFrom } from 'rxjs/operators';
import { CartState, CartActions } from '@wearejh/m2-pwa-cart-gql';
import { ofType } from 'redux-observable';
import { CartItem } from '@wearejh/m2-pwa-cart-gql';

import { DEFAULT_CURRENCY } from 'src/components/AppShell/app-shell.types';

interface NostoCartItem {
    name: string;
    product_id: string;
    sku_id: string;
    price_currency_code: string;
    quantity: number;
    unit_price: number;
}

const nostoCartItem = (item: CartItem): NostoCartItem => {
    return {
        name: item.product.name ?? '',
        product_id: item.product.id.toString() ?? '0',
        sku_id: item.product.sku.toString(),
        price_currency_code: DEFAULT_CURRENCY,
        quantity: item.quantity,
        unit_price: item.prices.price,
    };
};

export function nostoUpdateCart(action$: Observable<any>, state$: Observable<any>): Observable<never> {
    const $currentProducts: Observable<CartState['items']> = state$.pipe(pluck('cart', 'items'));

    return action$.pipe(
        ofType<CartActions>('Cart.FetchSuccess'),
        withLatestFrom($currentProducts),
        tap(([, items]) => {
            if (items) {
                // Take the items from the cart and turn them into the format required for Nosto
                const nostoCartProducts = items.map((item) => nostoCartItem(item));

                // Send the data to nosto whenever a cart is updated.
                // This is done by adding .viewCart().update() on the end
                // @see https://docs.nosto.com/techdocs/apis/frontend/implementation-guide-session-api/spa-basics-managing-sessions#setting-the-cart
                (window as any).nostojs((api) => {
                    api.defaultSession()
                        .setCart({ items: nostoCartProducts })
                        .viewCart()
                        .update()
                        .then((response) => {
                            api.placements.injectCampaigns(response.campaigns.content);
                        });
                });
            }
        }),
        ignoreElements(),
    );
}
