import React from 'react';
import { useContext } from 'react';

import { NostoContext } from 'src/components/Nosto';
import { NostoPlacementType } from 'src/components/Nosto/types';
import { PostsByArrayOfIDs } from 'src/wordpress/components/PostsByArrayOfIDs';

import NostoPlacementWrapper from '../Nosto/NostoPlacementWrapper';

type NostoProps = {
    type: NostoPlacementType;
    variant?: 'normal' | 'modal';
    blogPostIds?: string[];
};

export function Nosto(props: NostoProps) {
    const { isLoaded, nostoRecommendations } = useContext(NostoContext);

    if (!nostoRecommendations || !nostoRecommendations[props.type] || !isLoaded) {
        return (
            <>
                {/* If we don't have any IDs, we may still want to show blog posts */}
                {props?.blogPostIds && <PostsByArrayOfIDs postIds={props?.blogPostIds} />}
            </>
        );
    }

    return (
        <NostoPlacementWrapper
            type={props.type}
            nostoRecommendations={nostoRecommendations[props.type]}
            variant={props.variant}
            blogPostIds={props.blogPostIds}
        />
    );
}
