import {
    NostoPlacementsProps,
    NostoPlacementType,
    NostoRecommendations,
    NostoRecommendationsRaw,
} from 'src/components/Nosto/types';
import normalizeNostoCartProducts from 'src/components/Nosto/utilities/normalize-nosto-cart-products';
import normalizeNostoRecommendations from 'src/components/Nosto/utilities/normalize-nosto-recommendations';

import { nosto } from '../../../config.json';

type ConfigureNostoProps = NostoPlacementsProps & {
    api: any;
    placementType: NostoPlacementType;
};

export async function configureNosto(props: ConfigureNostoProps): Promise<NostoRecommendations> {
    const { api, placementType, categoryPath, cartData, productId, checkoutData } = props;

    return new Promise((resolve, reject) => {
        const handleResponse = (response: NostoRecommendationsRaw) => {
            // api.placements.injectCampaigns(response.campaigns.content);

            const recommendationsArray = normalizeNostoRecommendations(response);

            resolve(recommendationsArray);
        };

        switch (placementType) {
            case 'front':
                api.defaultSession()
                    .viewFrontPage()
                    .setPlacements(nosto.placements['front'])
                    .load()
                    .then(handleResponse)
                    .catch(reject);
                break;

            case 'category':
                api.defaultSession()
                    .viewCategory(categoryPath)
                    .setPlacements(nosto.placements['category'])
                    .load()
                    .then(handleResponse)
                    .catch(reject);
                break;

            case 'search':
                api.defaultSession()
                    .viewSearch(props.searchQuery)
                    .setPlacements(nosto.placements['search'])
                    .load()
                    .then(handleResponse)
                    .catch(reject);
                break;

            case 'notfound':
                api.defaultSession()
                    .viewNotFound()
                    .setPlacements(nosto.placements['notfound'])
                    .load()
                    .then(handleResponse)
                    .catch(reject);
                break;

            case 'cart':
                const nostoItems = normalizeNostoCartProducts(cartData);
                api.defaultSession()
                    .setCart({ items: nostoItems })
                    .viewCart()
                    .setPlacements(nosto.placements['cart'])
                    .load()
                    .then(handleResponse)
                    .catch(reject);
                break;

            case 'order':
                const nostoOrderItems = normalizeNostoCartProducts(cartData);
                const nostoInfo = {
                    email: checkoutData?.billingAddress?.email ?? '',
                    first_name: checkoutData?.billingAddress?.firstname ?? '',
                    last_name: checkoutData?.billingAddress?.lastname ?? '',
                    newsletter: false,
                    order_number: checkoutData?.orderId ?? '',
                    type: 'order',
                };
                api.defaultSession()
                    .addOrder({
                        external_order_ref: checkoutData?.orderId ?? '',
                        info: nostoInfo,
                        items: nostoOrderItems,
                    })
                    .setPlacements(nosto.placements['order'])
                    .load()
                    .then(handleResponse)
                    .catch(reject);
                break;

            case 'product':
                api.defaultSession()
                    .viewProduct(productId) // Set the product we are viewing. ProductID is passed in from the Nosto Component
                    .setPlacements(nosto.placements['product'])
                    .load()
                    .then(handleResponse)
                    .catch(reject);
                break;

            case 'addToBasket':
            default:
                api.defaultSession()
                    .viewOther()
                    .setPlacements(nosto.placements['addToBasket'])
                    .load()
                    .then(handleResponse)
                    .catch(reject);
        }
    });
}
