import React, { useContext, useMemo } from 'react';
import classnames from 'classnames';
import { connectStateResults } from 'react-instantsearch-dom';
import { MessageType } from '@wearejh/m2-pwa-engine/lib/types';

import { HeaderContext } from 'src/components/Header/Header';
import { AlgoliaContext } from 'src/components/Algolia/SearchDropdown/AlgoliaContext';
import { Messages } from 'src/components/Messages/Messages';
import { useDeps } from 'src/hooks/useDeps';

import classes from './SearchLayout.scss';
import 'src/components/Pagination/Pagination.scss';
import {
    AlgoliaCategories,
    AlgoliaPages,
    AlgoliaPopular,
    AlgoliaPosts,
    AlgoliaProducts,
    AlgoliaSuggestions,
} from './components';
import { AlgoliaHelpfulLinks } from './components/AlgoliaHelpfulLinks';

export const SearchAutocompleteDropdown = connectStateResults((props) => {
    const { showSearch } = useContext(HeaderContext);
    const { indexes } = useContext(AlgoliaContext);
    const env = useDeps().env;

    const hasSearchTerm = Boolean(props.searchState && props.searchState.query);

    const allResultsAreEmpty = useMemo(() => {
        const resultKeys = [
            `${env.ALGOLIA_INDEX_PREFIX}default_categories`,
            `${env.ALGOLIA_INDEX_PREFIX}default_pages`,
            `${env.ALGOLIA_INDEX_PREFIX}default_products`,
            `${env.ALGOLIA_INDEX_PREFIX}default_query_suggestions`,
            `${env.ALGOLIA_INDEX_PREFIX_WP}posts_post`,
        ];

        return resultKeys.every((key) => (props.allSearchResults?.[key]?.nbHits ?? 0) === 0);
    }, [env.ALGOLIA_INDEX_PREFIX, env.ALGOLIA_INDEX_PREFIX_WP, props.allSearchResults]);

    const displayNoMatchesMessage = hasSearchTerm && allResultsAreEmpty;
    const showQueryResults = hasSearchTerm && !allResultsAreEmpty;
    const showColdStart = !hasSearchTerm || (hasSearchTerm && allResultsAreEmpty);

    return (
        <div
            data-scrolllock-search
            className={classnames({
                [classes.searchResults]: true,
                [classes.searchResultsActive]: showSearch,
            })}
            data-test-id="SearchDropdown"
        >
            <div className={classes.searchResultsError}>
                {props.error && (
                    <Messages
                        messages={[
                            {
                                type: MessageType.Error,
                                text: props.error.message,
                            },
                        ]}
                    />
                )}
            </div>

            <ColdStart
                index={indexes.suggestions}
                displayNoMatchesMessage={displayNoMatchesMessage}
                hidden={!showColdStart}
            />

            <QuerySearch
                suggestions={indexes.suggestions}
                categories={indexes.categories}
                pages={indexes.pages}
                products={indexes.products}
                wp_articles={indexes.wp_articles}
                hidden={!showQueryResults}
            />
        </div>
    );
});

type ColdStartProps = {
    index: string;
    displayNoMatchesMessage: boolean;
    hidden: boolean;
};

export function ColdStart(props: ColdStartProps) {
    return (
        <div className={classes.searchResultsColdStart} hidden={props.hidden}>
            {props.displayNoMatchesMessage && (
                <div className={classes.searchResultsEmpty}>
                    <p>
                        Sorry, there are <b>no matches</b> for this term
                    </p>
                    <p>Please try another search or choose from one of the following popular options.</p>
                </div>
            )}

            {!props.displayNoMatchesMessage && (
                <div className={classes.searchResultsPopular}>
                    <AlgoliaPopular index={props.index} />
                </div>
            )}

            <div className={classes.searchResultsHelpfulLinks}>
                <AlgoliaHelpfulLinks />
            </div>
        </div>
    );
}

type QuerySearchProps = {
    categories: string;
    pages: string;
    products: string;
    suggestions: string;
    wp_articles: string;
    hidden: boolean;
};

export function QuerySearch(props: QuerySearchProps) {
    return (
        <>
            <div className={classes.searchResultsSuggestions} hidden={props.hidden}>
                <AlgoliaSuggestions index={props.suggestions} />
            </div>
            <div className={classes.searchResultsProducts} hidden={props.hidden}>
                <AlgoliaProducts index={props.products} />
            </div>
            <div className={classes.searchResultsCategories} hidden={props.hidden}>
                <AlgoliaCategories index={props.categories} />
            </div>
            <div className={classes.searchResultsPosts} hidden={props.hidden}>
                <AlgoliaPosts index={props.wp_articles} />
            </div>
            <div className={classes.searchResultsPages} hidden={props.hidden}>
                <AlgoliaPages index={props.pages} />
            </div>
        </>
    );
}
