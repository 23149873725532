import { StoreState } from 'src/types/global-types';

export function stateSelector(state: StoreState) {
    return {
        outdated: state.runtime.outdated,
        online: state.runtime.online,
    };
}

export default stateSelector;
