import React from 'react';
import { connectStateResults } from 'react-instantsearch-dom';

import classes from './AlgoliaStats.scss';

export const AlgoliaStats = connectStateResults(({ searchState, searchResults }) => {
    const pages = searchResults.hitsPerPage;
    const currentPageNumber = searchState.page;
    const totalNumberOfProducts = searchResults.nbHits;

    return (
        <span className={classes.stats}>
            {currentPageNumber} - {pages} of {totalNumberOfProducts} matches
        </span>
    );
});
