import React, { useCallback, useRef, useState, useEffect } from 'react';
import { skip, subscribeOn, tap } from 'rxjs/operators';
import { asyncScheduler } from 'rxjs';

import { Button } from 'src/components/Button/Button';
import { fireGTMEvent } from 'src/features/analytics/hooks/useGtm';
import { Svg } from 'src/components/Svg/Svg';
import { useBreakpointMatch } from 'src/hooks/useBreakpoint';
import useDebounceEffect from 'src/components/LoqateAll/utils/useDebounceEffect';
import { useHistoryLocationObservable } from 'src/hooks/router-hooks';
import { SearchTrigger } from 'src/components/Algolia/SearchDropdown/SearchAutocompleteWrapper';

import classes from './SearchLayout.scss';

type CustomSearchFormProps = {
    searchTerm: SearchTrigger;
    setShowSearch: (val: boolean) => void;
};

export const CustomSearchForm = (props: CustomSearchFormProps) => {
    const { searchTerm, setShowSearch } = props;
    const [debouncedValue, setDebouncedValue] = useState<string | undefined>(undefined);
    const { isMob } = useBreakpointMatch();
    const ref = useRef<HTMLInputElement>();

    /**
     * Set the search input value ready to debounce
     */
    const onChange = useCallback((event) => {
        setDebouncedValue(event.currentTarget.value);
    }, []);

    /**
     * Debounce the search input value
     */
    useDebounceEffect(
        () => {
            if (debouncedValue === undefined || typeof debouncedValue !== 'string') {
                return;
            }

            searchTerm.next(debouncedValue);
        },
        400,
        [debouncedValue],
    );

    /**
     * Clear the search input and reset the search term
     */
    const onClear = useCallback(() => {
        searchTerm.next('');

        if (ref.current) {
            ref.current.value = '';
        }
    }, [searchTerm]);

    /**
     * On location change:
     * - Clear search input
     * - Hide search results
     * - Reset the search term
     */
    const location$ = useHistoryLocationObservable();

    useEffect(() => {
        const sub = location$
            .pipe(
                skip(1),
                subscribeOn(asyncScheduler),
                tap(() => {
                    if (ref.current) {
                        onClear();
                        setShowSearch(false);
                    }
                }),
            )
            .subscribe();
        return () => sub.unsubscribe();
    }, [location$, onClear, searchTerm, setShowSearch]);

    const gtmSearch = useCallback(() => {
        fireGTMEvent({ event: 'search', search_term: (ref.current && ref.current.value) || '' });
    }, []);

    const hasValue = ref.current?.value;

    return (
        <form
            className={classes.searchForm}
            action="/catalogsearch/results"
            method="GET"
            autoComplete="off"
            data-test-id="SearchForm"
            onSubmit={gtmSearch}
        >
            <input
                className={classes.searchInput}
                id="search"
                ref={ref as any}
                name="query"
                type="search"
                onChange={onChange}
                onClick={() => setShowSearch(true)}
                aria-label="Search Box"
                placeholder="Search by product, category, or brand"
                data-test-id="SearchInput"
            />
            {hasValue && (
                <button className={classes.searchClear} type="button" aria-label="Clear" onClick={onClear}>
                    {isMob && <Svg name="delete" />}

                    {!isMob && 'Clear'}
                </button>
            )}
            <Button className={classes.searchButton} type="submit" variant="primary" aria-label="Search">
                <Svg name="search" />
            </Button>
        </form>
    );
};
