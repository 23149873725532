import { shallowEqual, useSelector } from 'react-redux';
import { CartItem, CartItemPrice } from '@wearejh/m2-pwa-cart-gql';

import type { AtbCartItem } from '../types';

import { selector } from './selector';

interface UseGetATBDataInterface {
    item?: AtbCartItem;
    prices?: CartItemPrice;
    product?: CartItem['product'];
    quantity?: number;
}

export const useGetAddToBasketData = (): UseGetATBDataInterface => {
    const { items } = useSelector(selector, shallowEqual);

    const addedProduct = items?.[0];

    return {
        item: addedProduct,
        prices: addedProduct?.prices,
        product: addedProduct?.product,
        quantity: addedProduct?.quantity ?? 1,
    };
};

export default useGetAddToBasketData;
