import React, {Fragment, useMemo} from 'react';

import { NostoPlacementType, NostoRecommendations } from 'src/components/Nosto/types';
import NostoSinglePlacement from 'src/components/Nosto/NostoSinglePlacement';
import { PostsByArrayOfIDs } from 'src/wordpress/components/PostsByArrayOfIDs';

type NostoPlacementProps = {
    nostoRecommendations: NostoRecommendations;
    variant?: 'normal' | 'modal';
    type: NostoPlacementType;
    blogPostIds?: string[];
};

import { nosto } from '../../config.json';

export const NostoPlacementWrapper = (props: NostoPlacementProps) => {
    const { nostoRecommendations, variant = 'normal' } = props;

    /**
     * Order the placements based on the order defined in the config
     */
    const order = nosto.placements[props.type];

    const orderedData = useMemo(() => {
        return  nostoRecommendations?.recommendations?.sort((a, b) => {
            return order.indexOf(a.placement_id) - order.indexOf(b.placement_id);
        });
    }, [nostoRecommendations?.recommendations, order])

    return (
        <section className="NostoRowWrapper">
            {orderedData?.map((recommendation, index) => {
                return (
                    <Fragment key={`${recommendation.placement_id}--${recommendation.result_id}`}>
                        <NostoSinglePlacement
                            placementTitle={recommendation.title}
                            placementProducts={recommendation.products}
                            placementId={recommendation.placement_id}
                            placementType={props.type}
                            slotRef={recommendation.result_id}
                            variant={variant}
                        />

                        {/* Make the blog post list appear between the first and subsequent nosto blocks */}
                        {index === 0 && props.type === 'product' && props?.blogPostIds && (
                            <PostsByArrayOfIDs postIds={props?.blogPostIds} />
                        )}
                    </Fragment>
                );
            })}
        </section>
    );
};

export default NostoPlacementWrapper;
