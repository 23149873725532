import React, { useMemo } from 'react';
import algoliasearch from 'algoliasearch';
import { useConstant } from '@wearejh/react-hooks/lib/useConstant';
import { SearchClient } from 'algoliasearch/dist/algoliasearch';
import algoliaAnalytics from 'search-insights';
import { useCustomerStatus } from '@wearejh/m2-pwa-user/lib/hooks/useCustomerStatus';
import { shallowEqual, useSelector } from 'react-redux';

import { useDeps } from 'src/hooks/useDeps';
import { StoreState } from 'src/types/global-types';
import { sanitizeGuestUserToken } from 'src/components/Algolia/utils/sanitizeGuestUserToken';

type Indexes = {
    categories: string;
    created_at_desc: string;
    ordered_qty_desc: string;
    pages: string;
    price_asc: string;
    price_desc: string;
    products: string;
    suggestions: string;
    wp_articles: string;
};

type AlgoliaContext = {
    client: null | SearchClient;
    indexes: Indexes;
    customerToken: string;
};

export const AlgoliaContext = React.createContext<AlgoliaContext>({
    client: null,
    indexes: {
        pages: '',
        products: '',
        suggestions: '',
        categories: '',
        price_asc: '',
        price_desc: '',
        ordered_qty_desc: '',
        created_at_desc: '',
        wp_articles: '',
    },
    customerToken: '',
});

const selector = (s: StoreState) => s.user.token;

export const AlgoliaContextProvider: React.FC = React.memo((props) => {
    const { isSignedIn } = useCustomerStatus();
    const token = useSelector(selector, shallowEqual);
    const { ALGOLIA_APP_ID, ALGOLIA_API_KEY } = useDeps().env;

    // For production use:
    const client = useConstant(() => algoliasearch(ALGOLIA_APP_ID, ALGOLIA_API_KEY));

    // For use with your own account:
    // const client = useConstant(() => algoliasearch('5PN63VVSW6', '79cf8765d53636613c8999ec0d9494c0'));

    let guestToken = '';
    const userToken = token ? sanitizeGuestUserToken(token) : '';

    algoliaAnalytics('init', {
        appId: ALGOLIA_APP_ID,
        apiKey: ALGOLIA_API_KEY,
        useCookie: true,
        cookieDuration: 60 * 60 * 1000,
    });

    algoliaAnalytics('getUserToken', null, (err, newUserToken) => {
        if (err) {
            console.error(err);
            return;
        }
        guestToken = newUserToken;
    });

    const customerToken = isSignedIn ? userToken : guestToken;

    algoliaAnalytics('setUserToken', customerToken);

    const env = useDeps().env;

    const indexes = useMemo(() => {
        return {
            categories: env.ALGOLIA_INDEX_PREFIX + 'default_categories',
            created_at_desc: env.ALGOLIA_INDEX_PREFIX + 'default_products_created_at_desc',
            ordered_qty_desc: env.ALGOLIA_INDEX_PREFIX + 'default_products_ordered_qty_desc',
            pages: env.ALGOLIA_INDEX_PREFIX + 'default_pages',
            price_asc: env.ALGOLIA_INDEX_PREFIX + 'default_products_price_default_asc',
            price_desc: env.ALGOLIA_INDEX_PREFIX + 'default_products_price_default_desc',
            products: env.ALGOLIA_INDEX_PREFIX + 'default_products',
            suggestions: env.ALGOLIA_INDEX_PREFIX + 'default_query_suggestions',
            wp_articles: env.ALGOLIA_INDEX_PREFIX_WP + 'posts_post',
        };
    }, [env.ALGOLIA_INDEX_PREFIX, env.ALGOLIA_INDEX_PREFIX_WP]);

    return (
        <AlgoliaContext.Provider
            value={{
                client,
                indexes,
                customerToken,
            }}
        >
            {props.children}
        </AlgoliaContext.Provider>
    );
});

export default AlgoliaContextProvider;
