import React from 'react';

import classes from 'src/components/AtbModal/AddToBasketModal.scss';
import { Button } from 'src/components/Button/Button';
import { Svg } from 'src/components/Svg/Svg';

interface AddToBasketModalHeaderProps {
    onClose?: () => void;
}

export const AddToBasketModalHeader = (props: AddToBasketModalHeaderProps) => {
    return (
        <div className={classes['addToBasketModalHeader']}>
            <p className={classes['addToBasketModalTitle']} data-test-id="ModalHeader_title" id="atb_modal_label">
                Added to Cart
            </p>
            <Button
                onClick={() => props.onClose?.()}
                variant="reset"
                className={classes['addToBasketModalCloseButton']}
            >
                <Svg name="close" />
            </Button>
        </div>
    );
};

export default AddToBasketModalHeader;
