import React from 'react';

import { ButtonLink } from '../Button/ButtonLink';
import { Button } from '../Button/Button';

import classes from './Modal.scss';

type ModalFooterProps = {
    buttonText?: string;
    buttonUrl?: string;
    cancelText?: string;
    children?: React.ReactNode;
    className?: string;
    onDismiss?(...args: any[]): void;
};
export function ModalFooter(props: ModalFooterProps) {
    return (
        <footer className={[classes.footer, props.className].join(' ')}>
            {props.children}

            <p className={classes.escape}>
                {props.buttonText && props.buttonUrl && (
                    <ButtonLink to={props.buttonUrl}>{props.buttonText}</ButtonLink>
                )}
                {props.onDismiss && (
                    <Button type="button" variant="reset" onClick={props.onDismiss}>
                        {props.cancelText || 'Cancel'}
                    </Button>
                )}
            </p>
        </footer>
    );
}
