import React from 'react';
import { Link } from 'react-router-dom';
import { useQuery } from '@apollo/react-hooks';

import { jhMenu, jhMenuVariables } from 'src/queries/__generated__/jhMenu';
import { useDeps } from 'src/hooks/useDeps';
import jhMenuQuery from 'src/queries/jhMenu.graphql';

import classes from './AlgoliaHelpfulLinks.scss';
import { NoResults } from './NoResults';

export function AlgoliaHelpfulLinks() {
    const { data, error } = useQuery<jhMenu, jhMenuVariables>(jhMenuQuery, {
        variables: { identifier: 'algolia-links' },
        ssr: useDeps().env.SSR_GQL,
    });

    if (error) {
        console.error('could not fetch Footer menus', error);
    }

    const hasData = data && data?.jhMenu?.length > 0;

    return (
        <div className={classes.links}>
            <h3 className={classes.linksTitle}>Helpful links</h3>

            {!hasData && <NoResults />}

            <ul className={classes.linksList}>
                {hasData &&
                    data?.jhMenu.map((hit) => {
                        if (!hit?.url) {
                            return;
                        }

                        return (
                            <li key={hit?.node_id} className={classes.linksItem}>
                                <Link to={hit?.url} className={classes.linksLink}>
                                    {hit?.title}
                                </Link>
                            </li>
                        );
                    })}
            </ul>
        </div>
    );
}
