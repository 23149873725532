import React, { useCallback, useContext, useEffect, useRef } from 'react';
import { of } from 'rxjs';
import { useDispatch, useSelector } from 'react-redux';

import { usePathnameObservable } from 'src/hooks/router-hooks';
import { SearchAutocomplete } from 'src/components/Algolia/SearchDropdown/SearchAutocomplete';
import { useDeps } from 'src/hooks/useDeps';
import { Nav } from 'src/components/Nav/Nav';
import { StoreState } from 'src/types/global-types';
import { AppMsg } from 'src/features/app/app.actions';

import { Container, Wrapper } from '../Layout';

import classes from './Header.scss';
import { HeaderControls } from './HeaderControls';
import { HeaderLogo } from './HeaderLogo';

type HeaderProps = {
    children: React.ReactNode;
};

export function Header(props: HeaderProps) {
    const { showSearch, showOverlay } = useContext(HeaderContext);

    return (
        <div>
            <div className={classes.overlay} data-active={showOverlay}></div>
            <Wrapper element="header" bg="grey" data-test-id="DefaultHeader" className={classes.header}>
                <Container className={classes.headerContainer}>
                    <div className={classes.searchOverlay} data-active={showSearch}></div>
                    <div className={classes.headerBrand}>
                        <HeaderLogo />
                    </div>
                    <div className={classes.headerSearch}>
                        <SearchAutocomplete />
                    </div>
                    <div className={classes.headerControls}>
                        <HeaderControls />
                    </div>
                </Container>
                {props.children}
            </Wrapper>
        </div>
    );
}

export const HeaderContext = React.createContext({
    showNav: false,
    showSearch: false,
    showOverlay: false,
    pathname$: of(''),
    goToAccount: () => {
        console.error('goToAccount not implemented');
    },
    setShowNav: (..._args: any[]) => {
        console.error('unable to open Navigation, `setShowNav` not implemented');
    },
    setShowSearch: (..._args: any[]) => {
        console.error('unable to open Search');
    },
});

export const HeaderProvider = React.memo(function HeaderProvider() {
    // Get the current modal state from redux store
    const appState = useSelector((state: StoreState) => state.app);

    const pathname$ = usePathnameObservable();

    /**
     * A callback for returning to an account screen
     */
    const { paths, replace } = useDeps();
    const dispatch = useDispatch();

    const goToAccount = useCallback(() => {
        dispatch(replace(paths.customer.account));
    }, [dispatch, paths, replace]);

    /**
     * Disable scroll when search or nav is open
     */

    const scrollPosition = useRef<number>(0);

    /**
     * The package `scroll-lock` is used to prevent the body from scrolling
     * However it does not work for multi level modals, it is only possible where one child is scrollable.
     * So we have to use a custom solution
     */
    useEffect(() => {
        const body = document?.querySelector('body');
        const lockScrollState = appState.openNav || appState.openSearch;
        scrollPosition.current = window.pageYOffset;

        if (lockScrollState && body) {
            body.style.overflow = 'hidden';
            body.style.position = 'fixed';
            body.style.top = `-${scrollPosition.current}px`;
            body.style.width = '100%';
        } else {
            if (body) {
                body.style.removeProperty('overflow');
                body.style.removeProperty('position');
                body.style.removeProperty('top');
                body.style.removeProperty('width');
                window.scrollTo(0, scrollPosition.current);
            }
        }
    }, [appState.openNav, appState.openSearch]);

    /**
     * Toggle nav open status in redux
     */
    const setShowNav = useCallback(
        (newState: boolean) => {
            dispatch(AppMsg('App.OpenNav', newState));
        },
        [dispatch],
    );

    /**
     * Toggle search open status in redux
     */
    const setShowSearch = useCallback(
        (newState: boolean) => {
            dispatch(AppMsg('App.ToggleSearch', newState));
        },
        [dispatch],
    );

    return (
        <HeaderContext.Provider
            value={{
                showNav: appState.openNav,
                setShowNav,
                showSearch: appState.openSearch,
                setShowSearch,
                showOverlay: appState.openOverlay,
                pathname$,
                goToAccount,
            }}
        >
            <Header>
                <Nav />
            </Header>
        </HeaderContext.Provider>
    );
});
