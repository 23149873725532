import type { CartItem } from '@wearejh/m2-pwa-cart-gql';

import { ProductSummaryChild } from 'src/components/Basket/utils/cartItemToProductSummary';
import { ProductPrice } from 'src/types/global-types';

export enum Value {
    Open = 'Open',
    Closed = 'Closed',
}

export type AtbCartItem = CartItem & {
    addedQuantity?: number;
};

export type AtbCartItems = AtbCartItem[];

type Cart = {
    items?: AtbCartItems;
};

export type State = {
    value: Value;
} & Cart;

export const EMPTY_ARR = [];

export type EventOpen = {
    type: 'AddToBasketModal.Open';
} & Cart;

export type EventClose = {
    type: 'AddToBasketModal.Close';
} & Cart;

export type Events = EventOpen | EventClose;

export type AddToBasketDetails = Partial<AtbCartItem> & {
    image?: string;
    items?: ProductSummaryChild[];
    name?: string;
    qty?: number;
    singleProductPrice?: ProductPrice;
    sku?: string;
    totalPrice?: ProductPrice;
};
