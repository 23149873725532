import React, { useEffect } from 'react';

import { ProductSummaryChild } from 'src/components/Basket/utils/cartItemToProductSummary';
import { ProductPrice } from 'src/types/global-types';
import classes from 'src/components/AtbModal/AddToBasketModal.scss';
import { ProductBlock } from 'src/components/AtbModal/ProductBlock';
import { ButtonLink } from 'src/components/Button/ButtonLink';
import { imagePath } from 'src/util/formatUrl';

import { AddToBasketDetails } from '../types';

import { AddToBasketModalTotals } from './AddToBasketModalTotals';

type AddToBasketModalContentProps = Pick<AddToBasketDetails, 'addedQuantity' | 'qty'> & {
    prices?: ProductPrice;
    productData: {
        image: string;
        name: string;
        items: ProductSummaryChild[];
        sku: string;
    };
    totalPriceAddedProducts: number;
    totalPriceAddedProductsInclVat: number;
    vat: boolean;
};

export const AddToBasketModalContent = (props: AddToBasketModalContentProps) => {
    const { productData } = props;

    useEffect(() => {
        if (productData.image) {
            const productImage = document.querySelector(
                `.${classes['addToBasketModalBody']} img:first-of-type`,
            ) as HTMLImageElement;
            const newImageUrl = productData.image;
            const currentImageUrl = productImage.src;

            if (productImage && newImageUrl !== currentImageUrl) {
                productImage.setAttribute('src', imagePath(newImageUrl));
            }
        }
    }, [productData.image]);

    return (
        <div className={classes['addToBasketModalBody']}>
            <ProductBlock product={productData} variant="large" />
            <AddToBasketModalTotals {...props} />
            <div className={classes['addToBasketActions']}>
                <ButtonLink data-test-id="ATB_Modal__Checkout" variant="primary" to="/basket">
                    Checkout
                </ButtonLink>
            </div>
        </div>
    );
};
