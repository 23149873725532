import React from 'react';

import { ProductPrice } from 'src/types/global-types';
import classes from 'src/components/AtbModal/AddToBasketModal.scss';
import { Price } from 'src/components/Helpers/Price';

import { AddToBasketDetails } from '../types';

type AddToBasketModalTotalsProps = Pick<AddToBasketDetails, 'addedQuantity' | 'qty'> & {
    prices?: ProductPrice;
    totalPriceAddedProducts: number;
    totalPriceAddedProductsInclVat: number;
    vat: boolean;
};

export const AddToBasketModalTotals = (props: AddToBasketModalTotalsProps) => {
    const { addedQuantity, prices, qty, totalPriceAddedProducts, totalPriceAddedProductsInclVat, vat } = props;

    const isTwoOrMoreQuantity = qty && qty > 1;

    return (
        <div className={classes['addToBasketTotals']}>
            <p className={classes['addToBasketQty']} data-test-id="Atb_qty">
                <span>
                    <small>Added to your Cart:</small>
                    {addedQuantity} @ <Price price={prices?.price ?? 0} priceIncl={prices?.price_incl ?? 0} vat={vat} />
                </span>
                {isTwoOrMoreQuantity && <small>Your cart contains {qty} of this product in total.</small>}
            </p>
            <p className={classes['addToBasketTotalsGrand']} data-test-id="Atb_total">
                <small>Total:</small>{' '}
                <Price price={totalPriceAddedProducts} priceIncl={totalPriceAddedProductsInclVat} vat={vat} />{' '}
            </p>
        </div>
    );
};
