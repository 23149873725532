import React from 'react';
import { Index, Configure, connectStateResults } from 'react-instantsearch-dom';
import { Link } from 'react-router-dom';

import { stripDomain } from 'src/util/sanitizeLink';
import { NoResults } from 'src/components/Algolia/SearchDropdown/components/NoResults';
import { LazyImg } from 'src/hooks/useLazyLoad';

import classes from './AlgoliaPosts.scss';
import CustomHighlight from './Highlight';

type AlgoliaPostsProps = {
    index: string;
};

export const CustomSearchBlog = connectStateResults(({ searchResults, searching }) => {
    const hits = searchResults?.hits;
    const hasResults = !searching && hits && hits?.length > 0;

    return (
        <div className={classes.posts}>
            <h3 className={classes.postsTitle}>Community</h3>

            {!hasResults && <NoResults />}

            {hasResults && (
                <ul className={classes.postsList}>
                    {hits.map((hit) => {
                        const url = stripDomain(hit.permalink);
                        return (
                            <li key={hit.objectID} className={classes.postsItem}>
                                <Link to={`/community${url}`} className={classes.postsLink}>
                                    <LazyImg
                                        src={hit.images.thumbnail.url}
                                        alt={hit.title}
                                        className={classes.postsLinkImage}
                                    />

                                    <span className={classes.postsLinkText}>
                                        <CustomHighlight attribute="post_title" hit={hit} />
                                    </span>
                                </Link>
                            </li>
                        );
                    })}
                </ul>
            )}
        </div>
    );
});

export function AlgoliaPosts(props: AlgoliaPostsProps) {
    return (
        <Index indexName={props.index}>
            <Configure hitsPerPage={3} />
            <CustomSearchBlog />
        </Index>
    );
}
