import { stripDomain } from 'src/util/sanitizeLink';
import { ProductPrice } from 'src/types/global-types';
import { ProductListItem } from 'src/components/CategoryListingPage/utils/productListItem';
import { productName } from 'src/util/productName';
import { ProductHit } from 'src/components/Algolia/utils/algolia.types';

import getHitProductLabels from './getHitProductLabels';

interface HitToProductItemProps {
    currencyCode: string;
    customerGroupId?: number;
    hit: ProductHit;
    index: string;
}

export function priceFromHit(hit: ProductHit, currency: string): ProductPrice {
    const price: ProductPrice = { price: 0, price_incl: 0 };

    if (hit && hit.price && hit.price[currency]) {
        const priceInc = hit.price[currency]?.default_formated || '€0.00';
        const regularPrice = hit.price[currency]?.default_original_formated;

        price.price_incl = parseFloat(priceInc.replace(',', '').slice(1));

        if(regularPrice) {
            price.regular_price = parseFloat(regularPrice.replace(',', '').slice(1));
        }
    } else {
        console.error('An algolia item did not have a `price_with_tax`', hit);
    }

    if (hit.price) {
        const match = hit.price[currency]?.default_formated || '€0.00';
        price.price = parseFloat(match.replace(',', '').slice(1));
    } else {
        console.error('An algolia item did not have a `price`', hit);
    }

    return price;
}

export function hitToProductItem(props: HitToProductItemProps): ProductListItem {
    const { hit, currencyCode, index, customerGroupId } = props;

    const price = priceFromHit(hit, currencyCode);
    const url = `${stripDomain(hit.url)}?objectId=${hit.objectID}&queryId=${hit.__queryID}&indexName=${index}`;
    const deliveryTypes = ['Home Delivery', 'Delivery from Delivery Centre', 'Dropship'];

    const labels = getHitProductLabels(hit, customerGroupId);

    return {
        click_collect: hit.click_and_collected === 'Yes',
        delivery: deliveryTypes.includes(hit.delivery_type),
        id: hit.objectID,
        // is_24_hour_delivery: false,
        // number_of_colors_available: null,
        // can_customise: false,
        labels: labels,
        name: productName(hit.name),
        // is_bestseller: false,
        price,
        small_image: {
            label: hit.name,
            url: hit.image_url,
        },
        url: url,
    };
}
