import React, { useMemo } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { ApolloError } from 'apollo-client';

import { DEFAULT_CURRENCY, DEFAULT_SYMBOL } from 'src/components/AppShell/app-shell.types';
import { useDeps } from 'src/hooks/useDeps';
import { storeConfig, storeConfig_cmsBlocks_items, storeConfigVariables } from 'src/queries/__generated__/storeConfig';
import storeConfigQuery from 'src/queries/storeConfig.graphql';

export const CMS_GLOBAL_BANNER = 'global-banner';

export type Currency = { code: string; symbol: string };
export type GlobalCmsBlocks = {
    globalBanner: string | undefined;
};

type ConfigContextType = {
    loading: boolean;
    error: ApolloError | undefined;
    product_url_suffix: string;
    category_url_suffix: string;
    currentCurrency: Currency;
    gtm: {
        enabled: boolean;
        id: string | null;
    };
    nosto: {
        enabled: boolean;
        id: string | null;
    };
    cmsBlocks: GlobalCmsBlocks;
};

const initialState: ConfigContextType = {
    product_url_suffix: '',
    category_url_suffix: '',
    currentCurrency: { code: DEFAULT_CURRENCY, symbol: DEFAULT_SYMBOL },
    gtm: {
        enabled: false,
        id: null,
    },
    nosto: {
        enabled: false,
        id: null,
    },
    loading: true,
    error: undefined,
    cmsBlocks: {
        globalBanner: undefined,
    },
};

export const ConfigContext = React.createContext<ConfigContextType>(initialState);

export const ConfigProvider: React.FC = React.memo((props) => {
    const { env } = useDeps();

    const { data, loading, error } = useQuery<storeConfig, storeConfigVariables>(storeConfigQuery, {
        variables: {
            cmsBlocks: [CMS_GLOBAL_BANNER],
        },
        ssr: env.SSR_GQL,
    });

    const api: ConfigContextType = useMemo(() => {
        const gtm = {
            enabled: env.GTM_ENABLED,
            id: env.GTM_ID,
        };

        const nosto = {
            enabled: env.NOSTO_ENABLED,
            id: env.NOSTO_ID,
        };

        function defaultApi() {
            return {
                loading,
                error,
                product_url_suffix: '',
                category_url_suffix: '',
                currentCurrency: {
                    code: 'EUR',
                    symbol: '\u20ac',
                },
                gtm,
                nosto,
                cmsBlocks: {
                    globalBanner: undefined,
                },
            };
        }

        if (loading || error) {
            return defaultApi();
        }

        if (data && data.storeConfig) {
            const cmsBlocks: GlobalCmsBlocks = {
                globalBanner: cmsBlockByIndentifier(data, CMS_GLOBAL_BANNER),
            };

            return {
                ...defaultApi(),
                cmsBlocks,
            };
        }
        return defaultApi();
    }, [data, env.GTM_ENABLED, env.GTM_ID, env.NOSTO_ENABLED, env.NOSTO_ID, error, loading]);

    return <ConfigContext.Provider value={api}>{props.children}</ConfigContext.Provider>;
});

function cmsBlockByIndentifier(globalConfig: storeConfig | undefined, identifier: string): string | undefined {
    if (!globalConfig || !globalConfig.cmsBlocks || !globalConfig.cmsBlocks?.items) {
        return undefined;
    }
    const items = globalConfig.cmsBlocks.items as storeConfig_cmsBlocks_items[];
    const match = items.find((block) => block?.identifier === identifier);
    return match?.content || undefined;
}
