import React, { useMemo, HTMLAttributes } from 'react';

import './ReviewStars.scss';

type Props = {
    count: string;
    id: string;
} & HTMLAttributes<any>;

export function ReviewStars(props: Props) {
    const svhHtml = useMemo(() => {
        //Create a star as a single string
        const stars = [...new Array(5)].map((_, index) => {
            //+1 so the index starts at 1
            const currentIndex = index + 1;
            const count = Number(props.count);

            //Star states
            const isActive = currentIndex <= count;
            const more = count > index;
            const less = count < currentIndex;

            //Star style
            const gradientId = props.id + currentIndex;
            const decimal = props.count.length > 2 ? props.count.substr(props.count.length - 1) : '';
            const decimalNumber = Number(decimal) * 10;

            const colour = () => {
                if (isActive) {
                    return 'fill: #ffdc03';
                } else if (less && more) {
                    return `fill: url(#${gradientId})`;
                } else {
                    return 'fill: #ffffff';
                }
            };

            const star = `<svg class="star" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="71.3px" height="72.1px" viewBox="0 0 71.3 72.1" style="${colour()}">
                            <defs>
                                <linearGradient id="${gradientId}">
                                    <stop offset="0%" stop-color="#ffdc03"/>
                                    <stop offset="${decimalNumber - 1}%" stop-color="#ffdc03"/>
                                    <stop offset="${decimalNumber}%" stop-color="#ffffff"/>
                                    <stop offset="100%" stop-color="#ffffff"/>
                                </linearGradient>
                            </defs>
                            <path d="M71,26c-0.7-1.8-2.3-3-4.2-3H48.1c-0.6-0.1-1.1-0.5-1.3-1L40.1,3c-0.9-2.4-3.6-3.6-5.9-2.7c-1.2,0.5-2.2,1.4-2.7,2.7l0,0l-6.7,18.9c-0.2,0.6-0.7,1.1-1.4,1.1H4.6c-2.5,0-4.6,2-4.6,4.5c0,1.4,0.6,2.7,1.7,3.6l15.9,13.1c0.5,0.4,0.7,1.1,0.5,1.7l-6.7,20c-0.8,2.4,0.5,5,2.9,5.8c1.4,0.5,2.9,0.2,4.1-0.6l16.3-12c0.5-0.4,1.3-0.4,1.8,0l16.3,12c2,1.6,4.9,1.3,6.5-0.7c1-1.3,1.3-3,0.7-4.5l-6.7-20c-0.2-0.6,0-1.3,0.5-1.7L69.7,31C71.2,29.8,71.7,27.8,71,26"/>
                        </svg>`;

            return star;
        });

        //Create one string of all stars
        return stars.join(' ');
    }, [props.count, props.id]);

    return <div className="stars" dangerouslySetInnerHTML={{ __html: svhHtml }} />;
}
