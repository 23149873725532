import classnames from 'classnames';
import React from 'react';

import { Svg } from '../Svg/Svg';
import { Button } from '../Button/Button';

import classes from './Modal.scss';

type Props = {
    className?: string;
    title: string;
    type?: 'success' | 'default';
    onDismiss?(...args: any[]): void;
};

export function ModalHeader(props: Props) {
    return (
        <header className={classnames(classes.header, props.className)}>
            <p className={classes.modalTitle} data-test-id="ModalHeader_title">
                {props.title}
            </p>
            {props.onDismiss && (
                <Button onClick={props.onDismiss} variant="reset" className={classes.close}>
                    <Svg name="close" />
                </Button>
            )}
        </header>
    );
}

ModalHeader.defaultProps = {
    type: 'default',
};
