import { Message } from '@wearejh/m2-pwa-engine/lib/types';
import React from 'react';

import { Modal } from 'src/components/Modal/Modal';
import { ModalHeader } from 'src/components/Modal/ModalHeader';
import { ModalBody } from 'src/components/Modal/ModalBody';
import { Messages } from 'src/components/Messages/Messages';
import { ModalFooter } from 'src/components/Modal/ModalFooter';

type Props = {
    close(): void;
    messages: Message[];
    title: string;
    cancelText?: string;
};

export function MessagesModal(props: Props) {
    return (
        <Modal type="popup" isOpen={true} onDismiss={props.close}>
            <ModalHeader title={props.title} onDismiss={props.close} />
            <ModalBody>
                <Messages messages={props.messages} />
            </ModalBody>
            <ModalFooter onDismiss={props.close} cancelText={props.cancelText} />
        </Modal>
    );
}
