import React from 'react';
import classnames from 'classnames';

import { LazyImg } from 'src/hooks/useLazyLoad';
import { ProductHitLabelData } from 'src/components/Algolia/utils/algolia.types';

import classes from './ProductLabel.scss';

export interface SingleLabel {
    id: number | null;

    /**
     * Image is 'null' if type is not 'picture'
     */
    image: string | null;

    name: string | null;

    /**
     * Possible types are: 'picture', 'shape' or 'text'
     */
    type: string | null;
}

export type ProductLabelProps =
    | ( SingleLabel
    | ProductHitLabelData ) & {
        size?: 'micro' | 'default';
};

/**
 * Individual product label
 */
export function ProductLabel(props: ProductLabelProps) {
    const { image, name, type, size } = props;

    if (size === 'micro') {
        return (
            <small
                className={classnames({
                    [classes.productLabelText]: true,
                    [classes.productLabelSmall]: true,
                    [classes.productLabelGreen]: name?.includes('free'),
                    [classes.productLabelOrange]: name?.includes('lead'),
                })}
            >
                {name}
            </small>
        );
    }

    if (type === 'picture' && image !== null) {
        return (
            <div>
                <LazyImg alt={name || ''} src={image} />
            </div>
        );
    }

    return (
        <div>
            <p className={classes.productLabelText}>{name}</p>
        </div>
    );
}
