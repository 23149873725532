import React, { useContext, useRef } from 'react';
import Slider, { Settings } from 'react-slick';
import { Link } from 'react-router-dom';

import { LazyPicture } from 'src/hooks/useLazyLoad';
import { HomeContext } from 'src/components/Home/HomeProvider';
import { ButtonLink } from 'src/components/Button/ButtonLink';

import classes from './HomeCarousel.scss';

export type CarouselItemActions = {
    url?: string;
    text?: string;
};

export interface CarouselItem {
    image: string;
    imageLap: string;
    imageMob: string;
    text?: string | null;
    title?: string;
    actions: CarouselItemActions[];
    align?: string;
    buttonColour: string;
    buttonTextColour: string;
}

type HomeCarouselProps = {
    items: CarouselItem[];
};

export const HomeCarousel: React.FC<HomeCarouselProps> = ({ items }) => {
    const sliderRef = useRef<Slider>(null);
    const slideCount = items.length > 1;

    const settings: Settings = {
        accessibility: true,
        arrows: slideCount,
        autoplay: slideCount,
        autoplaySpeed: 4000,
        className: classes.homeSlider,
        dots: slideCount,
        draggable: false,
        infinite: slideCount,
        pauseOnHover: true,
        slidesToScroll: 1,
        slidesToShow: 1,
        speed: 500,
    };

    return (
        <div className={classes.sliderWrapper}>
            <Slider ref={sliderRef} {...settings}>
                {items.map((item, index) => {
                    const actionCount = item.actions ? item.actions.length : 0;
                    const firstCta = item.actions[0];
                    const hasCtaOnly = !item.title && !item.text;

                    return (
                        <div key={item.title} className={classes.slide}>
                            {index === 0 && <link rel="preload" href={item.image} as="image" />}
                            <LazyPicture data-alt={item.title} className={classes.imageContainer}>
                                <source media="(min-width: 1200px)" srcSet={item.image} />
                                <source media="(min-width: 750px)" srcSet={item.imageLap} />
                                <source media="(max-width: 750px)" srcSet={item.imageMob} />
                            </LazyPicture>

                            <div className={classes.content}>
                                {firstCta && firstCta.url && <Link to={firstCta.url} className={classes.contentLink} />}
                                <div
                                    className={classes.contentInfo}
                                    data-ui-state-is-single={!slideCount}
                                    data-ui-state-is-cta-only={hasCtaOnly}
                                    data-ui-state-position={item.align}
                                >
                                    {item.title && (
                                        <h2 className={classes.contentTitle}>{decodeHTMLEntities(item.title)}</h2>
                                    )}

                                    {item.text && (
                                        <p className={classes.contentDesc}>{decodeHTMLEntities(item.text)}</p>
                                    )}

                                    <div className={classes.contentActions} data-ui-state-length={actionCount}>
                                        {item.actions.map((action) => {
                                            if (action.url && action.text) {
                                                return (
                                                    <ButtonLink
                                                        key={action.url}
                                                        to={action.url}
                                                        className={classes.action}
                                                        style={{
                                                            backgroundColor: item.buttonColour,
                                                            color: item.buttonTextColour,
                                                        }}
                                                    >
                                                        {action.text}
                                                    </ButtonLink>
                                                );
                                            }
                                        })}
                                    </div>
                                </div>
                            </div>
                        </div>
                    );
                })}
            </Slider>
        </div>
    );
};

export const HomeCarouselWrapper = React.memo(() => {
    const { carousel } = useContext(HomeContext);

    if (carousel.length === 0) {
        return null;
    }

    return <HomeCarousel items={carousel} />;
});

function decodeHTMLEntities(text) {
    const entities = [
        ['amp', '&'],
        ['#039', "'"],
    ];

    for (let i = 0, max = entities.length; i < max; ++i)
        text = text.replace(new RegExp('&' + entities[i][0] + ';', 'g'), entities[i][1]);

    return text;
}
