import type { Settings } from 'react-slick';
import React, { useCallback, useMemo, useRef, createRef } from 'react';
import Slider from 'react-slick';
import classnames from 'classnames';
import { DataStatus } from '@wearejh/m2-pwa-engine/lib/types';
import { useCartAdd } from '@wearejh/m2-pwa-cart-gql/lib/hooks/useCardAdd';

import type { NostoPlacementType, NostoProductDataRaw } from 'src/components/Nosto/types';
import { MessagesModal } from 'src/components/Modal/MessagesModal';
import { formatProducts } from 'src/components/Nosto/utilities';
import NostoProductCard from 'src/components/Nosto/NostoProductCard';
import { useProductListGtm } from 'src/components/CategoryListingPage/hooks/useProductListGtm';

import classes from './styles/NostoSinglePlacement.scss';

export type NostoSinglePlacementProps = {
    placementId?: string;
    placementProducts?: NostoProductDataRaw[];
    placementTitle?: string;
    placementType?: NostoPlacementType;
    slotRef?: string;
    variant?: 'normal' | 'modal';
};

export const NostoSinglePlacement = (props: NostoSinglePlacementProps) => {
    const { status, messages, addSimple, clearMessages } = useCartAdd();

    const { variant = 'normal' } = props;

    const isPending = status === DataStatus.Pending;
    const sliderRef = useRef<Slider>(null);
    const slidesToScrollAndShow = variant === 'modal' ? 3 : 4;

    const sliderSettings: Settings = {
        arrows: true,
        className: classnames({
            [classes.nostoSlider]: true,
            [classes.nostoSliderModal]: variant === 'modal',
        }),
        dots: true,
        draggable: false,
        infinite: false,
        responsive: [
            {
                breakpoint: 899,
                settings: {
                    slidesToShow: 3,
                },
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                },
            },
        ],
        slidesToShow: slidesToScrollAndShow,
    };

    // Format the products into a more Woodie's product card friendly manner
    const products = useMemo(() => formatProducts(props.placementProducts), [props.placementProducts]);

    // gtmSelectItem - fires on clicks of items
    const { gtmSelectItem } = useProductListGtm({
        products: products ?? [],
        listName: props.placementType,
        listId: props.placementType,
    });

    // Set up our product refs for Nosto tracking
    const productRefs = useMemo(() => {
        const refs = {};

        products.forEach((product) => {
            refs[product.id] = createRef();
        });

        return refs;
    }, [products]);

    const addToBasket = useCallback(
        (product) => {
            const { id, sku } = product;

            // Add product to basket
            addSimple({
                items: [
                    {
                        data: {
                            quantity: 1,
                            sku,
                        },
                    },
                ],
            });

            // Record Nosto tracking
            (window as any)?.nostojs((api) => {
                api?.defaultSession()?.reportAddToCart(id, productRefs[product.id].current)?.update();
            });
        },
        [addSimple, productRefs],
    );

    /**
     * Only run this code if we have a slider, as otherwise images should update as they are visible in the DOM.
     * This is required because lozad won't update hidden imagery. We need the images in the carousel to update whenever
     * the Nosto data changes.
     */

    if (products.length > 0) {
        return (
            <div className={classnames('nosto_element', classes['nosto_element'])}>
                <h2>{props.placementTitle}</h2>

                {messages.length > 0 && !isPending && (
                    <MessagesModal messages={messages} title="Sorry!" close={clearMessages} />
                )}

                <div className={classes['placementProductWrapper']}>
                    <Slider ref={sliderRef} {...sliderSettings}>
                        {products.map((product, index) => {
                            return (
                                <div key={`${product.name}--${index}`} ref={productRefs[product.id]}>
                                    <NostoProductCard
                                        product={product}
                                        onClick={() => gtmSelectItem(index)}
                                        onAddToBasket={addToBasket}
                                        slotRef={props.slotRef}
                                        isDisabled={isPending}
                                    />
                                </div>
                            );
                        })}
                    </Slider>
                </div>
            </div>
        );
    }

    return null;
};

export default NostoSinglePlacement;
