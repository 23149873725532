import React from 'react';
import { Index, Configure, connectStateResults } from 'react-instantsearch-dom';
import { Link } from 'react-router-dom';

import classes from './AlgoliaSuggestions.scss';
import CustomHighlight from './Highlight';
import { NoResults } from './NoResults';

export const CustomSuggestionHits = connectStateResults(({ searchResults, searching }) => {
    const hits = searchResults?.hits;
    const hasResults = !searching && hits && hits?.length > 0;

    return (
        <div className={classes.suggestions}>
            <h3 className={classes.suggestionsTitle}>Related searches</h3>

            {!hasResults && <NoResults />}

            {hasResults && (
                <ul className={classes.suggestionsList}>
                    {hits.map((hit) => {
                        return (
                            <li key={hit.objectID} className={classes.suggestionsItem}>
                                <Link
                                    to={`/catalogsearch/results?query=${encodeURI(hit.query)}`}
                                    className={classes.suggestionsLink}
                                >
                                    <CustomHighlight attribute="query" hit={hit} />
                                </Link>
                            </li>
                        );
                    })}
                </ul>
            )}
        </div>
    );
});

type AlgoliaSuggestionsProps = {
    index: string;
};

export function AlgoliaSuggestions(props: AlgoliaSuggestionsProps) {
    return (
        <Index indexName={props.index}>
            <Configure hitsPerPage={3} />
            <CustomSuggestionHits />
        </Index>
    );
}
