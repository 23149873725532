import { ProductHit, ProductHitLabelData } from 'src/components/Algolia/utils/algolia.types';

export const getHitProductLabels = (hit: ProductHit, customerGroupId?: number) => {
    let labels: ProductHitLabelData[] = [];

    const groupToFetch = customerGroupId !== undefined ? `customer_group_id ${customerGroupId}` : `customer_group_id 0`;

    const groupArray = hit?.labels?.[groupToFetch] ?? [];

    labels = groupArray.reduce((currentLabels: ProductHitLabelData[], labelGroup) => {
        const labels = labelGroup.map((label) => ({
            id: label?.label_id ?? undefined,
            image: label?.image ?? null,
            name: label?.name ?? undefined,
            position: label?.position ?? undefined,
            textLarge: label?.text_large ?? undefined,
            textMedium: label?.text_medium ?? undefined,
            textSmall: label?.text_small ?? undefined,
            type: label?.type ?? undefined,
        }));

        return [...currentLabels, ...labels];
    }, []);

    return labels;
};

export default getHitProductLabels;
