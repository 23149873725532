import React from 'react';
import { shallowEqual, useSelector } from 'react-redux';

import { StoreState } from 'src/types/global-types';
import { paths } from 'src/config.json';
import { HeaderProvider } from 'src/components/Header/Header';
import { Usps } from 'src/components/Usps/Usps';
import { HeaderCheckout } from 'src/components/Header/HeaderCheckout';
import { GlobalBanner } from 'src/components/AppShell/GlobalBanner';

type Props = Record<string, unknown>;

enum HeaderKind {
    Default = 'Default',
    Checkout = 'Checkout',
}

function stateSelector(state: StoreState) {
    return {
        pathname: state.router.location.pathname,
    };
}

export const HeaderVariant: React.FC<Props> = () => {
    const state = useSelector(stateSelector, shallowEqual);

    const headerVariant =
        state.pathname.indexOf(paths.checkout.checkout) === 0 ||
        state.pathname.indexOf(paths.checkout.adyenHPPRedirect) === 0
            ? HeaderKind.Checkout
            : HeaderKind.Default;

    if (headerVariant === HeaderKind.Checkout) {
        return <HeaderCheckout />;
    }

    return (
        <div>
            <HeaderProvider />
            <GlobalBanner />
            <Usps />
        </div>
    );
};
