import React from 'react';
import { useDispatch } from 'react-redux';
import { useLazyRegister } from '@wearejh/m2-pwa-engine/lib/hooks/useRegisterFeature';

import { createDebug } from 'src/util/debug';

import { AddToBasketModal } from './AddToBasketModal';
import { useGetAddToBasketData, useGetAddToBasketProductDetails, useGetAddToBasketModalState } from './hooks';
import { addToBasketModalRegister } from './addToBasketModal.register';

export const debug = createDebug('');

export const ProductAddToBasketModal = () => {
    useLazyRegister(addToBasketModalRegister, true, 'ProductAddToBasketModal');

    const dispatch = useDispatch();

    const { isAddToBasketModalOpen } = useGetAddToBasketModalState();

    const { item, product, prices, quantity } = useGetAddToBasketData();

    const detail = useGetAddToBasketProductDetails({
        item,
        product,
        prices,
        quantity,
    });

    return (
        <AddToBasketModal
            detail={detail}
            isOpen={isAddToBasketModalOpen}
            onClose={() => dispatch({ type: 'AddToBasketModal.Close' })}
        />
    );
};
