import { homeQuery } from 'src/queries/__generated__/homeQuery';
import { CategoryItem } from 'src/components/CategoryList/CategoryList';
import { imagePath } from 'src/util/formatUrl';
import { CarouselItem, CarouselItemActions } from 'src/components/Home/HomeCarousel';
import { stripDomain } from 'src/util/sanitizeLink';

import { PromotionItem } from '../Promotions';

const EMPTY_ARR = [];

export function categoriesFromHomeQuery<T extends homeQuery['jhMenu']>(items?: T): CategoryItem[] {
    if (!items) {
        return EMPTY_ARR;
    }

    return items.map((item) => {
        if (!item) {
            throw new Error('Cannot process category');
        }

        return {
            title: item.title || 'unknown',
            image: imagePath(item.image_url),
            link: item.url || '/',
            shortDescription: null,
        };
    });
}

export function carouselFromHomeQuery<T extends homeQuery['homepageCarousel']>(items?: T): CarouselItem[] {
    if (!items) {
        return EMPTY_ARR;
    }

    const cleanedItems: CarouselItem[] = [];

    items
        .sort((a, b) => (a?.sort_order ?? 0) - (b?.sort_order ?? 0))
        .forEach((item) => {
            if (!item || !item.enabled) {
                return;
            }

            const actions: CarouselItemActions[] = [];

            if (item.cta_url_1) {
                actions.push({
                    text: item.cta_text_1 ?? undefined,
                    url: stripDomain(item.cta_url_1),
                });
            }

            if (item.cta_url_2) {
                actions.push({
                    text: item.cta_text_2 ?? undefined,
                    url: stripDomain(item.cta_url_2),
                });
            }

            if (item.cta_url_3) {
                actions.push({
                    text: item.cta_text_3 ?? undefined,
                    url: stripDomain(item.cta_url_3),
                });
            }

            if (item.cta_url_4) {
                actions.push({
                    text: item.cta_text_4 ?? undefined,
                    url: stripDomain(item.cta_url_4),
                });
            }

            if (item.cta_url_5) {
                actions.push({
                    text: item.cta_text_5 ?? undefined,
                    url: stripDomain(item.cta_url_5),
                });
            }

            if (item.cta_url_6) {
                actions.push({
                    text: item.cta_text_6 ?? undefined,
                    url: stripDomain(item.cta_url_6),
                });
            }

            cleanedItems.push({
                image: imagePath(item.image),
                imageLap: imagePath(item.image_lap),
                imageMob: imagePath(item.image_mobile),
                text: item.text ?? undefined,
                title: item.title ?? undefined,
                align: item.align ?? 'center',
                actions: actions,
                buttonTextColour: item.button_text_colour ?? '#ffffff',
                buttonColour: item.button_colour ?? '#80ba27',
            });
        });
    return cleanedItems;
}

export function promotionsFromHomeQuery<T extends homeQuery['homepageCta']>(items?: T): PromotionItem[] {
    if (!items) {
        return EMPTY_ARR;
    }

    const cleanedPromotions: PromotionItem[] = [];

    items
        .sort((a, b) => (a?.sort_order ?? 0) - (b?.sort_order ?? 0))
        .forEach((item) => {
            if (!item || !item.enabled) {
                return;
            }

            cleanedPromotions.push({
                image: imagePath(item.image),
                title: item.title || 'unknown',
                url: item.url || '/',
            });
        });

    return cleanedPromotions;
}
